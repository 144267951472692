import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {Button} from 'mw-style-react';
import './Case.scss';
import Link from '../../i18n/Link';
import iconArrow from '../../images/arrow.svg';
import HeaderPage from '../../components/HeaderPage/HeaderPage';

class Case extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      casesOnPage: 9
    };
  }

  showMore(casesOnPage) {
    this.setState({
      casesOnPage: casesOnPage + 9
    });
  }

  render() {
    const {data, intl, type} = this.props;
    const {casesOnPage} = this.state;
    let list = data.list;
    if (type === 'media') {
      list = data.list[intl.locale];
    }

    const copyList = JSON.parse(JSON.stringify(list));
    const renderCases = copyList.splice(0, casesOnPage);
    const showButton = list.length > casesOnPage;

    return (
      <section className="case">
        <div className="case__header__top"/>
        <HeaderPage data={data}/>
        <div className="case__wrapper">
          <div className="case__content">
            <div className="grid-wrapper case__cases__list">
              {renderCases.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className="col-4 case__cases__item"
                >
                  <div className="case__cases__item__header">
                    <img src={item.image} alt={item.title}/>
                  </div>
                  <div className="case__cases__item__body">
                    <div className="case__cases__item__date__wrapper">
                      <span className="case__cases__item__date">{item.date}</span>
                      {item.source &&
                        <span className="case__cases__item__source">{item.source}</span>
                      }
                    </div>
                    <h3 className="case__cases__item__title">{intl.formatMessage({id: item.title})}</h3>
                    <div className="case__cases__item__link">
                      <p>
                        {intl.formatMessage({id: item.more})}
                      </p>
                      <img src={iconArrow} alt="More"/>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          {showButton &&
            <Button
              className={'button__default case__button'}
              label={intl.formatMessage({id: data.showMore})}
              onClick={() => this.showMore(casesOnPage)}
            />
          }
        </div>
      </section>
    );
  }
}

Case.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.object,
};

export default injectIntl(Case);

