import React, { PureComponent } from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import CasePage from '../templates/Case';

import {
  // news,
  imageMedia,
  tild,
  apiMedia,
  privat24,
  telecom,
  pipeDrive,
  tina,
  pumbMedia,
  robotMedia,
  corezoidOpenApi2016,
  financialServicesSectors,
  findevrSf2015,
  finovateSpring2015,
  finovateSpring2016,
  finovateTopleassAtm2014,
  newCoreAlternative,
  telecomVsMessengers,
  visaBitcoinVityaz,
  vityazPrivatBunk,
  intelectBunk,
  mumbu,
  metroMedia,
  medici,
  deposit,
  bitcoin,
  chatbors,
  corezoidVisa,
  gift,
  neobank,
  digital,
  db2f04b9001c,
  monoTinkoff,
  artjokerVCRU,
  yelobankmedia
} from '../images';

let locationOrigin = '';
if (typeof window !== 'undefined') {
  locationOrigin = location.origin;
}

// при изменении кейсов так же заменить на странице resource
const cases = {
  title: 'mediaTitle',
  subTitle: 'mediaSubTitle',
  image: 'news.png',
  button: {
    text: 'mediaButtonText',
    link: '/case-studies'
  },
  showMore: 'mediaShowMore',
  list: {
    ru: [
      {
        image: db2f04b9001c,
        date: '25.10.2019',
        source: 'Phnr.com',
        title: 'Сеть Premier запустила первый в Украине чат-бот для гостиниц',
        link: 'https://www.phnr.com/chat-bot',
        more: 'Читать полностью'
      },
      {
        image: digital,
        date: '15.05.2019',
        source: 'Future',
        title: 'Александр Витязь: «Классический бизнес не умеет инвестировать в технологии…»',
        link: 'https://futuremagazine.io/ru/klassicheskij-biznes-ne-umeet-investirovat-v-tehnologii/',
        more: 'Читать полностью'
      },
      {
        image: vityazPrivatBunk,
        date: '18.02.2019',
        source: 'Liga.net',
        title: 'Александр Витязь: "Сейчас в ПриватБанке все становится одномерным — как в 2003 году"',
        link: 'http://project.liga.net/projects/vityaz_privatbank/',
        more: 'Читать полностью'
      },
      {
        image: intelectBunk,
        date: '28.10.2014',
        source: 'Forbes',
        title: 'Интеллект в банке',
        link: `${locationOrigin}/Forbes_10_2014.pdf`,
        more: 'Читать полностью'
      },
      {
        image: imageMedia,
        date: '20.02.2016',
        source: 'Bankir.ru',
        title: 'Visa убивает банки',
        link: 'http://bankir.ru/publikacii/20160220/visa-ubivaet-banki-10007242/',
        more: 'Читать полностью'
      },
      {
        image: tild,
        date: '09.07.2015',
        source: 'MMR',
        title: 'Александр Витязь: «Все пути ведут в дискретную математику»',
        link: 'http://mmr.ua/show/aleksandr_vityazy:_vse_puti_vedut_v_diskretnuyu_matematiku',
        more: 'Читать полностью'
      },
      {
        image: visaBitcoinVityaz,
        date: '25.02.2016',
        source: 'Forklog',
        title: 'VISA и биткоин изменят банковскую систему вместе',
        link: 'http://forklog.com/visa-i-bitkoin-izmenyat-bankovskuyu-sistemu-vmeste/',
        more: 'Читать полностью'
      },
      {
        image: apiMedia,
        date: '18.04.2016',
        source: 'Bankir.ru',
        title: 'Темная сторона API: как дублирование замедляет индустрию',
        link: 'http://bankir.ru/publikacii/20160418/temnaya-storona-api-kak-dublirovanie-zamedlyaet-industriyu-10007437/',
        more: 'Читать полностью'
      },
      {
        image: privat24,
        date: '20.12.2016',
        source: 'AIN',
        title: 'Что будет с «Приват24» и IT-системами «ПриватБанка»',
        link: 'https://ain.ua/2016/12/20/chto-budet-s-privat24',
        more: 'Читать полностью'
      },
      {
        image: corezoidVisa,
        date: '08.12.2016',
        source: 'Bankir.ru',
        title: 'Share.CreditCard: история победы команды Corezoid — Приватбанк в Visa Challenge на хакатоне Money 20\\20',
        link: 'http://bankir.ru/publikacii/20161208/share-creditcard-istoriya-pobedy-komandy-corezoid-privatbank-v-visa-challenge-na-khakatone-money-20-20-10008364/',
        more: 'Читать полностью'
      },
      {
        image: telecom,
        date: '10.06.2016',
        source: 'Bankir.ru',
        title: 'Последний шанс для телекомов?',
        link: 'http://bankir.ru/publikacii/20160610/poslednii-shans-dlya-telekomov-10007653/',
        more: 'Читать полностью'
      },
      {
        image: pipeDrive,
        date: '06.10.2016',
        source: 'Bzns.media',
        title: 'Как связка PipeDrive + Corezoid помогает десяти менеджерам управлять сотней тысяч клиентов',
        link: 'https://bzns.media/tekhnologii/kak_svyazka_pipedrive_corezoid_pomogaet_desyati_menedzheram_upravlyat_sotney_tysyach_klientov-353926/',
        more: 'Читать полностью'
      },
      {
        image: tina,
        date: '27.07.2018',
        source: 'MMR',
        title: 'Viber выпустил именные стикеры Тины Кароль',
        link: 'http://mmr.ua/show/viber_vypustil_imennye_stikery_tiny_karoly#1128373996.1530616349',
        more: 'Читать полностью'
      },
      {
        image: pumbMedia,
        date: '08.10.2018',
        source: 'Finclub',
        title: 'ПУМБ создал на основе Corezoid Витязя свое «цифровое ядро»',
        link: 'https://finclub.net/news/pumb-sozdal-na-osnove-corezoid-vityazya-svoe-tsifrovoe-yadro.html',
        more: 'Читать полностью'
      },
      {
        image: robotMedia,
        date: '21.01.2019',
        source: 'Reklamaster',
        title: 'Украинский школьник создал карманного репетитора-чат-бота для подготовки к ЗНО',
        link: 'http://reklamaster.com/business-and-innovations/ukrainskij-shkolnik-sozdal-karmannogo-repetitora-chat-bota-dlja-podgotovki-k-zno/',
        more: 'Читать полностью'
      },
      {
        image: mumbu,
        date: '15.01.2019',
        source: 'Finovate',
        title: 'Process as Product: Middleware and Mambu Strengthen Alliance',
        link: 'https://finovate.com/process-as-product-middleware-and-mambu-strengthen-alliance/',
        more: 'Read more'
      },
      {
        image: metroMedia,
        date: '25.04.2018',
        source: 'Corezoid',
        title: 'Metro Cash & Carry Ukraine starts issuing digital customer cards instantly in Viber',
        link: 'https://corezoid.com/2018/04/metro-cash-carry-ukraine-starts-issuing-digital-customer-cards-instantly-in-viber/',
        more: 'Read more'
      },
      {
        image: newCoreAlternative,
        date: '09.05.2016',
        source: 'American Banker',
        title: 'New Core Alternative: Runs in the Cloud, Built by a Bank',
        link: 'https://www.americanbanker.com/news/new-core-alternative-runs-in-the-cloud-built-by-a-bank',
        more: 'Read more'
      },
      {
        image: medici,
        date: '30.11.2016',
        source: 'Let\'s talk payments',
        title: 'Corezoid Achieves AWS Financial Services Competency Status for Core Systems Implementations',
        link: 'https://gomedici.com/corezoid-achieves-aws-financial-services-competency-status-for-core-systems-implementations/',
        more: 'Read more'
      },
      {
        image: deposit,
        date: '20.04.2016',
        source: 'International Banker',
        title: 'The dark side of apis in fintech: why duplicates slow down innovation',
        link: 'https://internationalbanker.com/technology/dark-side-apis-fintech-duplicates-slow-innovation/',
        more: 'Read more'
      },
      {
        image: bitcoin,
        date: '01.05.2016',
        source: 'Сointelegraph',
        title: 'Bitcoin and Banks Can Work Together, Interview With PrivatBank’s Alexander Vityaz',
        link: 'https://cointelegraph.com/news/bitcoin-and-banks-can-work-together-interview-with-privatbanks-alexander-vityaz',
        more: 'Read more'
      },
      {
        image: medici,
        date: '12.10.2015',
        source: 'Let\'s talk payments',
        title: 'Why Can’t Global Banks Introduce a P2P Money Transfer System Within Ten Years?',
        link: 'https://letstalkpayments.com/why-cant-global-banks-introduce-a-p2p-money-transfer-system-within-ten-years/',
        more: 'Read more'
      },
      {
        image: chatbors,
        date: '07.07.2016',
        source: 'VentureBeat',
        title: 'Chatbots have an operating system problem',
        link: 'http://venturebeat.com/2016/07/07/chatbots-have-an-operating-system-problem/',
        more: 'Read more'
      },
      {
        image: telecomVsMessengers,
        date: '27.12.2016',
        source: 'TMC',
        title: 'Users Are Abandoning Traditional Text and Voice for Messengers',
        link: 'http://unified-communications.tmcnet.com/topics/unified-communications/articles/428337-users-abandoning-traditional-text-voice-messengers.htm',
        more: 'Read more'
      },
      {
        image: financialServicesSectors,
        date: '20.12.2016',
        source: 'It Briefcase',
        title: 'IT Briefcase Exclusive Interview: The Changing IT Landscape in the Financial Sector',
        link: 'http://www.itbriefcase.net/it-briefcase-interview-it-landscape-in-the-financial-sector',
        more: 'Read more'
      },
      {
        image: finovateTopleassAtm2014,
        date: '24.11.2014',
        source: 'Finovate',
        title: 'Video Finovate 2014 - Topless ATM, Best of show',
        link: 'https://www.youtube.com/watch?v=TC7KlXo4kyU&t=53s',
        more: 'Read more'
      },
      {
        image: finovateSpring2015,
        date: '21.05.2015',
        source: 'Finovate',
        title: 'Video Finovate 2015 - Corezoid, smart nodes',
        link: 'https://www.youtube.com/watch?v=5KnrH0diIDg&t=24s',
        more: 'Read more'
      },
      {
        image: findevrSf2015,
        date: '7.10.2015',
        source: 'Findevr',
        title: 'Video FinDevr 2015 - Corezoid, Deepmemo',
        link: 'http://findevr.com/videos/findevr-2015-corezoidmiddleware/',
        more: 'Read more'
      },
      {
        image: finovateSpring2016,
        date: '25.05.2016',
        source: 'Finovate',
        title: 'Video Finovate 2016 - from API to Processes',
        link: 'https://www.youtube.com/watch?v=o70fp4iN-3w&t=68s',
        more: 'Read more'
      },
      {
        image: corezoidOpenApi2016,
        date: '18.11.2016',
        source: 'TFSC',
        title: 'Video Oslo - Open APIs conference',
        link: 'https://www.youtube.com/watch?v=ZhssGRpL7js',
        more: 'Read more'
      },
      {
        image: gift,
        date: '12.09.2017',
        source: 'Khaleejtimes',
        title: 'A gift for someone back home? Send them a token',
        link: 'https://www.khaleejtimes.com/20170912/no-title',
        more: 'Read more'
      },
      {
        image: neobank,
        date: '9.07.2018',
        source: 'Bankinnovation',
        title: 'How TBC Bank Created a Neobank Called ‘Space’ in Just 8 Months',
        link: 'https://bankinnovation.net/2018/07/how-tbc-bank-created-a-neobank-called-space-in-8-months/',
        more: 'Read more'
      },
      {
        image: monoTinkoff,
        date: '05.12.2019',
        source: 'Liga.net',
        title: 'Появится ли у monobank конкурент в Украине? Интервью с бывшим топ-менеджером Tinkoff',
        link: 'https://tech.liga.net/technology/interview/poyavitsya-li-u-monobank-konkurent-v-ukraine-intervyu-s-byvshim-top-menedjerom-tinkoff',
        more: 'Read more'
      },
      {
        image: artjokerVCRU,
        date: '01.10.2020',
        source: 'VC.RU',
        title: 'Заменят ли бизнес аналитики программистов? Кейсы по Corezoid от команды Artjoker',
        link: 'https://vc.ru/services/163238-zamenyat-li-biznes-analitiki-programmistov-keysy-po-corezoid-ot-komandy-artjoker',
        more: 'Read more'
      },
      {
        image: yelobankmedia,
        date: '25.09.2020',
        source: 'yelo.az',
        title: 'Yelo Bank представил новую услугу интернет банкинга',
        link: 'https://www.yelo.az/ru/news/115/yelo-bank-has-introduced-a-brand-new-internet-banking-service-for-corporate-clients',
        more: 'Read more'
      },
    ],
    en: [
      {
        image: db2f04b9001c,
        date: '25.10.2019',
        source: 'Phnr.com',
        title: 'Premier Hotels and Resorts is happy to announce the launch of the first for Ukraine hotel chat bot',
        link: 'https://www.phnr.com/en/chat-bot',
        more: 'Read more'
      },
      {
        image: mumbu,
        date: '15.01.2019',
        source: 'Finovate',
        title: 'Process as Product: Middleware and Mambu Strengthen Alliance',
        link: 'https://finovate.com/process-as-product-middleware-and-mambu-strengthen-alliance/',
        more: 'Read more'
      },
      {
        image: metroMedia,
        date: '25.04.2018',
        source: 'Corezoid',
        title: 'Metro Cash & Carry Ukraine starts issuing digital customer cards instantly in Viber',
        link: 'https://corezoid.com/2018/04/metro-cash-carry-ukraine-starts-issuing-digital-customer-cards-instantly-in-viber/',
        more: 'Read more'
      },
      {
        image: newCoreAlternative,
        date: '09.05.2016',
        source: 'American Banker',
        title: 'New Core Alternative: Runs in the Cloud, Built by a Bank',
        link: 'https://www.americanbanker.com/news/new-core-alternative-runs-in-the-cloud-built-by-a-bank',
        more: 'Read more'
      },
      {
        image: medici,
        date: '30.11.2016',
        source: 'Let\'s talk payments',
        title: 'Corezoid Achieves AWS Financial Services Competency Status for Core Systems Implementations',
        link: 'https://gomedici.com/corezoid-achieves-aws-financial-services-competency-status-for-core-systems-implementations/',
        more: 'Read more'
      },
      {
        image: deposit,
        date: '20.04.2016',
        source: 'International Banker',
        title: 'The dark side of apis in fintech: why duplicates slow down innovation',
        link: 'https://internationalbanker.com/technology/dark-side-apis-fintech-duplicates-slow-innovation/',
        more: 'Read more'
      },
      {
        image: bitcoin,
        date: '01.05.2016',
        source: 'Сointelegraph',
        title: 'Bitcoin and Banks Can Work Together, Interview With PrivatBank’s Alexander Vityaz',
        link: 'https://cointelegraph.com/news/bitcoin-and-banks-can-work-together-interview-with-privatbanks-alexander-vityaz',
        more: 'Read more'
      },
      {
        image: medici,
        date: '12.10.2015',
        source: 'Let\'s talk payments',
        title: 'Why Can’t Global Banks Introduce a P2P Money Transfer System Within Ten Years?',
        link: 'https://letstalkpayments.com/why-cant-global-banks-introduce-a-p2p-money-transfer-system-within-ten-years/',
        more: 'Read more'
      },
      {
        image: chatbors,
        date: '07.07.2016',
        source: 'VentureBeat',
        title: 'Chatbots have an operating system problem',
        link: 'http://venturebeat.com/2016/07/07/chatbots-have-an-operating-system-problem/',
        more: 'Read more'
      },
      {
        image: telecomVsMessengers,
        date: '27.12.2016',
        source: 'TMC',
        title: 'Users Are Abandoning Traditional Text and Voice for Messengers',
        link: 'http://unified-communications.tmcnet.com/topics/unified-communications/articles/428337-users-abandoning-traditional-text-voice-messengers.htm',
        more: 'Read more'
      },
      {
        image: financialServicesSectors,
        date: '20.12.2016',
        source: 'It Briefcase',
        title: 'IT Briefcase Exclusive Interview: The Changing IT Landscape in the Financial Sector',
        link: 'http://www.itbriefcase.net/it-briefcase-interview-it-landscape-in-the-financial-sector',
        more: 'Read more'
      },
      {
        image: finovateTopleassAtm2014,
        date: '24.11.2014',
        source: 'Finovate',
        title: 'Video Finovate 2014 - Topless ATM, Best of show',
        link: 'https://www.youtube.com/watch?v=TC7KlXo4kyU&t=53s',
        more: 'Read more'
      },
      {
        image: finovateSpring2015,
        date: '21.05.2015',
        source: 'Finovate',
        title: 'Video Finovate 2015 - Corezoid, smart nodes',
        link: 'https://www.youtube.com/watch?v=5KnrH0diIDg&t=24s',
        more: 'Read more'
      },
      {
        image: findevrSf2015,
        date: '7.10.2015',
        source: 'Findevr',
        title: 'Video FinDevr 2015 - Corezoid, Deepmemo',
        link: 'http://findevr.com/videos/findevr-2015-corezoidmiddleware/',
        more: 'Read more'
      },
      {
        image: finovateSpring2016,
        date: '25.05.2016',
        source: 'Finovate',
        title: 'Video Finovate 2016 - from API to Processes',
        link: 'https://www.youtube.com/watch?v=o70fp4iN-3w&t=68s',
        more: 'Read more'
      },
      {
        image: corezoidOpenApi2016,
        date: '18.11.2016',
        source: 'TFSC',
        title: 'Video Oslo - Open APIs conference',
        link: 'https://www.youtube.com/watch?v=ZhssGRpL7js',
        more: 'Read more'
      },
      {
        image: corezoidVisa,
        date: '19.12.2016',
        source: 'Corezoid',
        title: 'Share.CreditCard: Corezoid hits the jackpot in Las Vegas - team wins Visa challenge at Money 20\\20 hackathon',
        link: 'https://corezoid.com/2016/12/share-creditcard-corezoid-hits-the-jackpot/',
        more: 'Read more'
      },
      {
        image: gift,
        date: '12.09.2017',
        source: 'Khaleejtimes',
        title: 'A gift for someone back home? Send them a token',
        link: 'https://www.khaleejtimes.com/20170912/no-title',
        more: 'Read more'
      },
      {
        image: neobank,
        date: '9.07.2018',
        source: 'Bankinnovation',
        title: 'How TBC Bank Created a Neobank Called ‘Space’ in Just 8 Months',
        link: 'https://bankinnovation.net/2018/07/how-tbc-bank-created-a-neobank-called-space-in-8-months/',
        more: 'Read more'
      },
      {
        image: yelobankmedia,
        date: '25.09.2020',
        source: 'yelo.az',
        title: 'Yelo Bank has introduced a brand new Internet banking service for corporate clients.',
        link: 'https://www.yelo.az/en/news/115/yelo-bank-has-introduced-a-brand-new-internet-banking-service-for-corporate-clients',
        more: 'Read more'
      },
    ]
  }
};

class Media extends PureComponent {
  sort(array) {
    return array.sort((item1, item2) => {
      const dA1 = item1.date.split('.');
      const date1 = new Date(`${dA1[1]}/${dA1[0]}/${dA1[2]}`).getTime();
      const dA2 = item2.date.split('.');
      const date2 = new Date(`${dA2[1]}/${dA2[0]}/${dA2[2]}`).getTime();
      return date2 - date1;
    });
  }

  render() {
    // cases.list.ru = [
    //   ...cases.list.ru,
    //   ...cases.list.en,
    // ];

    cases.list.ru = this.sort(cases.list.ru);
    cases.list.en = this.sort(cases.list.en);

    return (
      <Layout {...this.props}>
        <CasePage
          data={cases}
          type={'media'}
          {...this.props}
        />
      </Layout>
    );
  }
}

export default withIntl(Media);
